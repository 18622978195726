import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import routerObject from "../../router/router";
import classNames from "../../utils/classNames";

interface ISideBar {
  navigation?: any;
}

const SideBars: React.FC<ISideBar> = (props: ISideBar) => {
  const location = useLocation();
  const isActive = (elemPath: string) => {
    const currentUrl = new URL(elemPath, window.location.origin);
    const currentPath = currentUrl.pathname;
    const currentSearch = currentUrl.search;
    const isPathMatch = location.pathname.startsWith(currentPath);
    const isSearchMatch = currentSearch
      ? location.search === currentSearch
      : true;
    return isPathMatch && isSearchMatch;
  };
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (category: any) => {
    setActiveCategory(activeCategory === category ? null : category);
  };
  return (
    <nav className="flex-1 px-2 " id="sidebar-drag">
      {routerObject.map((item: any, index: number) => {
        const panelRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
          if (activeCategory === item?.name && panelRef.current) {
            panelRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        }, [activeCategory, item?.name]);

        return item?.children?.length ? (
          <React.Fragment key={index}>
            <div className="sidebar">
              <button
                onClick={() => handleCategoryClick(item?.name)}
                className={classNames(
                  item.current
                    ? "bg-indigo-800 text-white"
                    : "text-white hover:bg-indigo-600",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
                )}
              >
                <img
                  src={item?.icon}
                  alt=""
                  className="w-[19px] h-[19px] mr-2"
                />
                {item?.name}
              </button>
              {activeCategory === item?.name && (
                <div className="ml-4" ref={panelRef}>
                  {item?.children?.map((elem: any) => {
                    const match = isActive(elem.path);
                    return (
                      <NavLink
                        key={elem.name}
                        to={elem.path}
                        className={({ isActive }) => {
                          return `${
                            match
                              ? "bg-indigo-800 text-white"
                              : "text-white hover:bg-indigo-600"
                          } group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md my-1`;
                        }}
                      >
                        <img
                          src={elem?.icon}
                          alt=""
                          className="w-[18px] h-[18px]"
                        />
                        {elem?.name}
                      </NavLink>
                    );
                  })}
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <NavLink
            key={item.name}
            to={item.path}
            className={({ isActive }) => {
              return `${
                isActive
                  ? "bg-indigo-800 text-white"
                  : "text-indigo-100 hover:bg-indigo-600"
              } group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full my-1`;
            }}
            onClick={() => handleCategoryClick(item?.name)}
          >
            <img src={item?.icon} alt="" className="w-[19px] h-[19px] mr-2" />
            {item.name}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default SideBars;
