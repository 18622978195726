import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  QUERY_BOOKING_DATE,
  QUERY_BOOKING_DETAILS,
  QUERY_BOOKING_STATUS,
  QUERY_RESTAURANT_TABLE,
} from "../queries";
import { useEffect, useState } from "react";
import moment from "moment";

const BookingStatusComponent: any = Loadable({
  loader: () => import("../../components/BookingSetting/BookingStatus"),
  loading: DataLoadSpinner,
});

type Props = {};

const BookingStatus = (props: Props) => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const { data: bookingDateData, loading: bookingDateDataLoading } = useQuery(
    QUERY_BOOKING_DATE,
    {
      fetchPolicy: "network-only",
    }
  );
  const { data: restaurantTableData, loading: restaurantTableLoading } =
    useQuery(QUERY_RESTAURANT_TABLE, {
      fetchPolicy: "network-only",
      variables: {
        displayInBooking: true,
        subTable_Isnull: true,
      },
    });

  const [
    bookingStatus,
    { data: bookingStatusData, loading: bookingStatusLoading },
  ] = useLazyQuery(QUERY_BOOKING_STATUS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const fetchBookingStatus = async () => {
      if (bookingDateData && selectedDate) {
        await bookingStatus({
          variables: {
            selectedDate: moment(selectedDate).format("yyyy-MM-DD"),
          },
        });
      }
    };
    fetchBookingStatus();
  }, [bookingDateData, bookingStatus, selectedDate]);

  const [
    bookingDetails,
    { data: bookingDetailsData, loading: bookingDetailsLoading },
  ] = useLazyQuery(QUERY_BOOKING_DETAILS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const fetchBookingStatus = async () => {
      if (selectedCustomer) {
        await bookingDetails({
          variables: {
            id: selectedCustomer,
          },
        });
      }
    };
    fetchBookingStatus();
  }, [bookingDateData, bookingDetails, selectedCustomer]);

  return (
    <div>
      {restaurantTableLoading ||
      bookingStatusLoading ||
      bookingDateDataLoading ? (
        <DataLoadSpinner />
      ) : (
        <BookingStatusComponent
          restaurantTableData={restaurantTableData?.restaurantTables?.edges}
          bookingStatusData={bookingStatusData?.bookingStatus}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          bookingDateData={bookingDateData?.bookedDate?.bookedDates}
          setSelectedCustomer={setSelectedCustomer}
          bookingDetailsData={bookingDetailsData?.bookings?.edges[0]}
          bookingDetailsLoading={bookingDetailsLoading}
        />
      )}
    </div>
  );
};

export default BookingStatus;
