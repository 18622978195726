import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { useMutation, useQuery } from "@apollo/client";
import {
  QUERY_ALL_BOOKING_CATEGORIES,
  QUERY_HOLIDAY_PERIOD,
  QUERY_RESTAURANT_TABLE,
  QUERY_TERMS_AND_CONDITION,
} from "../queries";
import {
  CREATE_BOOKING_AVAILABILITY,
  CREATE_UPDATE_HOLIDAY_PERIOD,
  DELETE_CATEGORY_BOOKING__AVAILABILITY,
  EDIT_RESTAURANT_TABLE,
  UPDATE_BOOKING_AVAILABILITY,
} from "../mutations";
import { toast } from "react-toastify";

const BookingSettingComponent: any = Loadable({
  loader: () => import("../../components/BookingSetting/BookingSetting"),
  loading: DataLoadSpinner,
});
type Props = {};

const BookingSetting = (props: Props) => {
  const { data: RestaurantTableData, loading: restaurantTableLoading } =
    useQuery(QUERY_RESTAURANT_TABLE, {
      variables: {
        subTable_Isnull: true,
      },
      fetchPolicy: "network-only",
    });

  const { data: termAndConditionData } = useQuery(QUERY_TERMS_AND_CONDITION, {
    fetchPolicy: "network-only",
  });

  const { data: holidayPeriodData } = useQuery(QUERY_HOLIDAY_PERIOD, {
    fetchPolicy: "network-only",
  });

  const { data: defaultBookingData, loading: defaultBookingLoading } = useQuery(
    QUERY_ALL_BOOKING_CATEGORIES,
    {
      fetchPolicy: "network-only",
      variables: {
        pk: "bf3a1130-afe6-46c4-a528-2db7ccd113fc",
      },
    }
  );

  const {
    data: allBookingCategoriesData,
    loading: allBookingCategoriesLoading,
  } = useQuery(QUERY_ALL_BOOKING_CATEGORIES, {
    fetchPolicy: "network-only",
  });

  const [editRestaurantTable] = useMutation(EDIT_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: QUERY_RESTAURANT_TABLE,
      },
    ],
  });

  const handleUpdateTableDisplay = async (e: any, values: any) => {
    console.log(e, values);
    await editRestaurantTable({
      variables: {
        id: values.pk,
        title: values.title,
        capacity: values.capacity,
        note: values.note,
        displayInBooking: e,
        isFunctional: true,
        floor: values?.floor?.pk,
      },
    });
  };

  const [createUpdateHolidayPeriod] = useMutation(
    CREATE_UPDATE_HOLIDAY_PERIOD,
    {
      refetchQueries: [
        {
          query: QUERY_HOLIDAY_PERIOD,
        },
      ],
    }
  );

  const handleholidayPeriod = async (values: any, setOpenHolidayModel: any) => {
    const response = await createUpdateHolidayPeriod({
      variables: {
        holidayStartDate: values.holidayStartDate,
        holidayEndDate: values.holidayEndDate,
      },
    });
    if (response.data?.createUpdateHolidayPeriod?.success) {
      toast.success("Holiday Period is added successfully");
      setOpenHolidayModel(false);
    } else {
      toast.error("Error");
    }
  };

  const [createBookingAvailability] = useMutation(CREATE_BOOKING_AVAILABILITY, {
    refetchQueries: [
      {
        query: QUERY_ALL_BOOKING_CATEGORIES,
      },
    ],
  });

  const handleBookingAvailability = async (
    values: any,
    setOpenCreateCategory: any
  ) => {
    const response = await createBookingAvailability({
      variables: {
        termsAndConditions: values.termsAndConditions,
        isDefault: values.isDefault,
        daysDetails: values.daysDetails.map((category: any) => ({
          categoryName: category?.categoryName,
          days: Object.values(category.tables[0]).map((day: any) => ({
            ...day,
            openAt: day.openAt.padStart(8, "0"),
            endAt: day.endAt.padStart(8, "0"),
            intervalTime: day.intervalTime.padStart(8, "0"),
          })),
        })),
      },
    });
    if (response.data?.createBookingAvailability?.success) {
      toast.success("Create successfully");
      setOpenCreateCategory(false);
    } else {
      toast.error("Error");
    }
  };

  const [updateBookingAvailability] = useMutation(UPDATE_BOOKING_AVAILABILITY, {
    refetchQueries: [
      {
        query: QUERY_ALL_BOOKING_CATEGORIES,
        variables: {
          pk: "bf3a1130-afe6-46c4-a528-2db7ccd113fc",
        },
      },
      {
        query: QUERY_ALL_BOOKING_CATEGORIES,
      },
      {
        query: QUERY_TERMS_AND_CONDITION,
      },
    ],
  });

  const handleUpdateBookingAvailability = async (
    values: any,
    setOpenCreateCategory: any
  ) => {
    const response = await updateBookingAvailability({
      variables: {
        termsAndConditions: values.termsAndConditions,
        isDefault: values.isDefault,
        daysDetails: values.daysDetails.map((category: any) => ({
          categoryName: category.categoryName,
          categoryId: category.pk,
          days: Object.values(category.tables[0]).map((day: any) => ({
            ...day,
            openAt: day.openAt.padStart(8, "0"),
            endAt: day.endAt.padStart(8, "0"),
            intervalTime: day.intervalTime.padStart(8, "0"),
          })),
        })),
      },
    });

    if (response.data?.updateBookingAvailability?.success) {
      toast.success("Update booking successfully");
      setOpenCreateCategory(false);
    } else {
      toast.error("Error");
    }
  };

  const [deleteCategoryBookingAvailabilitiy] = useMutation(
    DELETE_CATEGORY_BOOKING__AVAILABILITY,
    {
      refetchQueries: [
        {
          query: QUERY_ALL_BOOKING_CATEGORIES,
        },
      ],
    }
  );

  const handleDeleteBookingAvailabilitiy = async (id: string) => {
    const response = await deleteCategoryBookingAvailabilitiy({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deleteBookingCategory?.success) {
      toast.success(response.data?.deleteBookingCategory?.message);
    } else {
      toast.error(
        response.data?.deleteBookingCategory?.errors?.nonFieldErrors[0]?.message
      );
    }
  };
  return (
    <>
      <BookingSettingComponent
        RestaurantTableData={RestaurantTableData}
        handleUpdateTableDisplay={handleUpdateTableDisplay}
        restaurantTableLoading={restaurantTableLoading}
        defaultBookingLoading={defaultBookingLoading}
        defaultBookingData={defaultBookingData}
        handleBookingAvailability={handleBookingAvailability}
        QUERY_TERMS_AND_CONDITION={QUERY_TERMS_AND_CONDITION}
        termAndConditionData={termAndConditionData?.termsAndConditions}
        handleholidayPeriod={handleholidayPeriod}
        holidayPeriodData={holidayPeriodData?.holidayPeriods}
        handleUpdateBookingAvailability={handleUpdateBookingAvailability}
        allBookingCategoriesData={allBookingCategoriesData?.bookingCategories}
        allBookingCategoriesLoading={allBookingCategoriesLoading}
        handleDeleteBookingAvailabilitiy={handleDeleteBookingAvailabilitiy}
      />
    </>
  );
};

export default BookingSetting;
