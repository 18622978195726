import { gql } from "@apollo/client";

export const SYNC_SERVER = gql`
  mutation SYNC_SERVER($schemaName: String) {
    syncWithServer(input: { schemaName: $schemaName }) {
      errors
      success
      message
    }
  }
`;

export const REFUND_ORDER = gql`
  mutation ($objectId: String!) {
    refundOrder(input: { objectId: $objectId }) {
      errors
      success
      message
    }
  }
`;

export const TOGGLE_ATTENDANCE_CLOCKIN_CLOCKOUT = gql`
  mutation ($pin: String!) {
    toggleClockInClockOut(input: { pin: $pin }) {
      errors
      success
      message
      clockInClockOut {
        id
        clockIn
        clockOut
      }
    }
  }
`;

export const CREATE_UPDATE_REWARD_SETTINGS = gql`
  mutation (
    $amount: String
    $point: String
    $minRequiredPoint: String
    $isActive: String
  ) {
    createUpdateRewardPointSetting(
      input: {
        amount: $amount
        point: $point
        minRequiredPoint: $minRequiredPoint
        isActive: $isActive
      }
    ) {
      errors
      success
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation ADD_CATEGORY(
    $posScreenCategoryId: String
    $name: String!
    $parent: String
    $status: String
    $image: Upload
  ) {
    createCategory(
      input: {
        posScreenCategoryId: $posScreenCategoryId
        name: $name
        status: $status
        parent: $parent
        image: $image
      }
    ) {
      category {
        name
        pk
      }
      success
      errors
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UPDATE_CATEGORY(
    $pk: String!
    $name: String!
    $parent: String
    $status: String
    $image: Upload
  ) {
    updateCategory(
      input: {
        categoryId: $pk
        name: $name
        status: $status
        parent: $parent
        image: $image
      }
    ) {
      success
      errors
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DELETE_CATEGORY($objectId: String!) {
    deleteCategory(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const CREATE_TAX = gql`
  mutation CREATE_TAX($title: String!, $rate: String!, $isIncluded: String) {
    createTax(input: { title: $title, rate: $rate, isIncluded: $isIncluded }) {
      success
      tax {
        pk
        title
        rate
      }
      errors
    }
  }
`;

export const DELETE_TAX = gql`
  mutation DELETE_TAX($objectId: String!) {
    deleteTax(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const EDIT_TAX = gql`
  mutation EDIT_TAX(
    $id: String!
    $title: String!
    $rate: String!
    $isIncluded: String
  ) {
    updateTax(
      input: { id: $id, title: $title, rate: $rate, isIncluded: $isIncluded }
    ) {
      success
      errors
      tax {
        id
        pk
        title
        rate
      }
    }
  }
`;

export const CREATE_PRINTER = gql`
  mutation CREATE_PRINTER(
    $name: String!
    $type: String!
    $department: String!
    $location: String!
  ) {
    createPrinter(
      input: {
        name: $name
        type: $type
        department: $department
        location: $location
      }
    ) {
      success
      errors
      printer {
        name
        department
        type
        location
      }
    }
  }
`;

export const DELETE_PRINTER = gql`
  mutation DELETE_PRINTER($objectId: String!) {
    deletePrinter(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const EDIT_PRINTER = gql`
  mutation EDIT_PRINTER(
    $id: String!
    $name: String!
    $department: String!
    $type: String!
    $location: String
  ) {
    updatePrinter(
      input: {
        id: $id
        name: $name
        department: $department
        location: $location
        type: $type
      }
    ) {
      success
      errors
      printer {
        id
        pk
        name
        department
        type
        location
      }
    }
  }
`;

export const CREATE_SERVICE_CHARGE = gql`
  mutation QUERY_SERVICE_CHARGES($title: String!, $rate: String!) {
    createServiceCharge(input: { title: $title, rate: $rate }) {
      success
      errors
      serviceCharge {
        id
        pk
        title
        rate
      }
    }
  }
`;

export const EDIT_SERVICE_CHARGE = gql`
  mutation EDIT_SERVICE_CHARGE($id: String!, $title: String!, $rate: String!) {
    updateServiceCharge(input: { id: $id, title: $title, rate: $rate }) {
      success
      errors
      serviceCharge {
        id
        pk
        title
        rate
      }
    }
  }
`;

export const DELETE_SERVICE_CHARGE = gql`
  mutation DELETE_SERVICE_CHARGE($objectId: String!) {
    deleteServiceCharge(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const CREATE_POS_DISCOUNT = gql`
  mutation CREATE_POS_DISCOUNT(
    $title: String!
    $discountType: String!
    $rateAmount: String!
  ) {
    createPosDiscount(
      input: {
        title: $title
        discountType: $discountType
        rateAmount: $rateAmount
      }
    ) {
      success
      errors
    }
  }
`;

export const DELETE_POS_DISCOUNT = gql`
  mutation DELETE_POS_DISCOUNT($objectId: String!) {
    deleteDiscount(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const EDIT_POS_DISCOUNT = gql`
  mutation EDIT_POS_DISCOUNT(
    $id: String!
    $title: String
    $rateAmount: String
    $discountType: String
  ) {
    updatePosDiscount(
      input: {
        id: $id
        title: $title
        rateAmount: $rateAmount
        discountType: $discountType
      }
    ) {
      success
      errors
    }
  }
`;

export const CREATE_RESTAURANT_TABLE = gql`
  mutation CREATE_RESTAURANT_TABLE(
    $title: String!
    $floor: String
    $capacity: String!
    $note: String!
  ) {
    createRestaurantTable(
      input: { title: $title, capacity: $capacity, floor: $floor, note: $note }
    ) {
      success
      errors
    }
  }
`;

export const DELETE_RESTAURANT_TABLE = gql`
  mutation DELETE_RESTAURANT_TABLE($objectId: String!) {
    deleteRestaurantTable(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const EDIT_RESTAURANT_TABLE = gql`
  mutation EDIT_RESTAURANT_TABLE(
    $id: String!
    $title: String!
    $capacity: String!
    $floor: String
    $note: String!
    $isFunctional: String
    $displayInBooking: String
  ) {
    updateRestaurantTable(
      input: {
        id: $id
        title: $title
        capacity: $capacity
        floor: $floor
        note: $note
        isFunctional: $isFunctional
        displayInBooking: $displayInBooking
      }
    ) {
      success
      errors
    }
  }
`;

export const CREATE_RESTAURANT_FLOOR = gql`
  mutation CREATE_RESTAURANT_FLOOR($title: String!, $note: String!) {
    createFloor(input: { title: $title, note: $note }) {
      success
      errors
    }
  }
`;
export const EDIT_RESTAURANT_FLOOR = gql`
  mutation EDIT_RESTAURANT_FLOOR($id: String!, $title: String!, $note: String) {
    updateFloor(input: { id: $id, title: $title, note: $note }) {
      success
      errors
    }
  }
`;
export const DELETE_RESTAURANT_FLOOR = gql`
  mutation DELETE_RESTAURANT_FLOOR($objectId: String!) {
    deleteFloor(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const MEREGE_RESTAURANT_TABLE = gql`
  mutation MEREGE_RESTAURANT_TABLE($toBeMergeTableId: [String]) {
    mergeRestaurantTable(input: { toBeMergeTableId: $toBeMergeTableId }) {
      success
      errors
    }
  }
`;
export const CREATE_SUB_TABLE = gql`
  mutation ($id: String!, $quantity: Int!) {
    createSubTable(input: { id: $id, quantity: $quantity }) {
      errors
      success
      messages
    }
  }
`;
export const DISABLE_SUB_TABLE = gql`
  mutation ($id: String!) {
    disableSubTable(input: { id: $id }) {
      errors
      success
    }
  }
`;

export const SPLIT_RESTAURANT_TABLE = gql`
  mutation SPLIT_RESTAURANT_TABLE($toBeSplitTableId: String) {
    splitRestaurantTable(input: { toBeSplitTableId: $toBeSplitTableId }) {
      success
      errors
      messages
    }
  }
`;

export const SWAP_RESTAURANT_TABLE = gql`
  mutation SWAP_RESTAURANT_TABLE($fromTable: String, $toTable: String) {
    swapRestaurantTable(input: { fromTable: $fromTable, toTable: $toTable }) {
      success
      errors
    }
  }
`;

export const CREATE_TERMINAL = gql`
  mutation CREATE_TERMINAL($name: String!, $printer: String!, $note: String) {
    createTerminal(input: { name: $name, printer: $printer, note: $note }) {
      success
      errors
    }
  }
`;

export const EDIT_TERMINAL = gql`
  mutation EDIT_TERMINAL(
    $id: String!
    $name: String!
    $printer: String
    $note: String
  ) {
    updateTerminal(
      input: { id: $id, name: $name, printer: $printer, note: $note }
    ) {
      success
      errors
    }
  }
`;

export const EDIT_RECEIPT_SETTING = gql`
  mutation EDIT_RECEIPT_SETTING(
    $id: String!
    $netAmount: String
    $amountAfterDiscount: String
    $gst: String
    $surCharge: String
    $serviceCharge: String
    $eftposCharge: String
    $grossAmount: String
    $openPopupAfterRemotePrint: String
    $serviceChargeToggleCheckbox: String
    $compulsoryKitchenPrint: String
    $isOpenPopupAfterPayment: String
  ) {
    updateTerminalSetting(
      input: {
        terminalId: $id
        netAmount: $netAmount
        amountAfterDiscount: $amountAfterDiscount
        gst: $gst
        surCharge: $surCharge
        serviceCharge: $serviceCharge
        eftposCharge: $eftposCharge
        grossAmount: $grossAmount
        openPopupAfterRemotePrint: $openPopupAfterRemotePrint
        serviceChargeToggleCheckbox: $serviceChargeToggleCheckbox
        compulsoryKitchenPrint: $compulsoryKitchenPrint
        isOpenPopupAfterPayment: $isOpenPopupAfterPayment
      }
    ) {
      success
      errors
    }
  }
`;

export const EDIT_CDU_SETTING = gql`
  mutation EDIT_CDU_SETTING(
    $id: String!
    $isActive: String
    $isScreenSaveOnly: String
    $slideDuration: String
  ) {
    updateTerminalSetting(
      input: {
        terminalId: $id
        isActive: $isActive
        isScreenSaveOnly: $isScreenSaveOnly
        slideDuration: $slideDuration
      }
    ) {
      success
      errors
    }
  }
`;
export const EDIT_SCREEN_SAVER = gql`
  mutation EDIT_SCREEN_SAVER($id: String!, $image: Upload!) {
    createScreenSaverImage(input: { screenSaver: $id, image: $image }) {
      success
      errors
    }
  }
`;
export const DELETE_SCREEN_SAVE_IMAGE = gql`
  mutation DELETE_SCREEN_SAVE_IMAGE($id: String!) {
    deleteScreenSaverImage(input: { screenSaverId: $id }) {
      success
      errors
      message
    }
  }
`;
export const EDIT_SCALE_SETTING = gql`
  mutation EDIT_TERMINAL_SCALE_SETTING(
    $id: String!
    $isActiveScale: String
    $scaleBaudRate: String
    $scaleDateBits: String
    $scaleParity: String
    $scalePort: String
    $scaleStopBits: String
  ) {
    updateTerminalSetting(
      input: {
        terminalId: $id
        isActiveScale: $isActiveScale
        scaleBaudRate: $scaleBaudRate
        scaleDateBits: $scaleDateBits
        scaleParity: $scaleParity
        scalePort: $scalePort
        scaleStopBits: $scaleStopBits
      }
    ) {
      success
      errors
    }
  }
`;

export const DELETE_TERMINAL = gql`
  mutation DELETE_TERMINAL($objectId: String!) {
    deleteTerminal(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;
export const CREATE_UPDATE_BASIC_SETTING = gql`
  mutation CREATE_UPDATE_BASIC_SETTING(
    $title: String
    $faviconIcon: Upload
    $logo: Upload
    $timezone: String
    $currency: String
    $country: String
    $productWisePirnt: String
    $serviceCharge: String
    $isAutoPrint: String
    $isProductWisePrint: String
    $noOfColumns: String
    $printProductByCategoryOrder: String
    $resetOrder: String
    $isStockMaintain: String
    $qrOrderUrl: String
  ) {
    createUpdateWebsiteConfigurations(
      input: {
        title: $title
        faviconIcon: $faviconIcon
        logo: $logo
        timezone: $timezone
        currency: $currency
        country: $country
        productWisePrint: $productWisePirnt
        serviceCharge: $serviceCharge
        isAutoPrint: $isAutoPrint
        isProductWisePrint: $isProductWisePrint
        noOfColumns: $noOfColumns
        printProductByCategoryOrder: $printProductByCategoryOrder
        resetOrder: $resetOrder
        isStockMaintain: $isStockMaintain
        qrOrderUrl: $qrOrderUrl
      }
    ) {
      success
      errors
      basicWebsiteConfigurations {
        title
        timezone
        currency
        serviceCharge {
          rate
        }
        logo
      }
    }
  }
`;

export const UPLOAD_MEDIA = gql`
  mutation UPLOAD_MEDIA(
    $contentType: String!
    $objectId: String!
    $media: Upload!
  ) {
    createMedia(
      input: { contentType: $contentType, objectId: $objectId, media: $media }
    ) {
      success
      errors
      media {
        media
        pk
        objectId
      }
    }
  }
`;

export const DELETE_MEDIA = gql`
  mutation DELETE_MEDIA($objectId: String!) {
    deleteMedia(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const UPDATE_PRICE = gql`
  mutation (
    $costPrice: String
    $sellingPrice: String
    $contentType: String
    $objectId: String
  ) {
    createUpdatePrice(
      input: {
        costPrice: $costPrice
        sellingPrice: $sellingPrice
        contentType: $contentType
        objectId: $objectId
      }
    ) {
      errors
      success
      price {
        id
        pk
        costPrice
        sellingPrice
      }
    }
  }
`;

export const CREATE_ROLES = gql`
  mutation CREATE_ROLES($name: String!) {
    createGroup(input: { name: $name }) {
      success
      errors
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation DELETE_ROLES($objectId: String!) {
    deleteGroup(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation EDIT_ROLE($id: String!, $name: String!) {
    updateGroup(input: { id: $id, name: $name }) {
      success
      errors
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation ($id: String!, $name: String!, $permissions: [Int]) {
    updateGroup(input: { id: $id, name: $name, permissions: $permissions }) {
      success
      errors
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER(
    $username: String
    $email: String
    $mobileNumber: String
    $confirmPassword: String!
    $password: String!
  ) {
    createUser(
      input: {
        username: $username
        email: $email
        mobileNumber: $mobileNumber
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      success
      errors
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UPDATE_USER_PROFILE(
    $id: String!
    $fullName: String
    $dob: String
    $mobileNumber: String
    $email: String
    $trackingNumber: String
    $fullAddress: String
  ) {
    updateCustomer(
      input: {
        id: $id
        fullName: $fullName
        dob: $dob
        mobileNumber: $mobileNumber
        email: $email
        trackingNumber: $trackingNumber
        fullAddress: $fullAddress
      }
    ) {
      success
      errors
    }
  }
`;

export const ASSIGN_USER_ROLE = gql`
  mutation ASSIGN_USER_ROLE($id: String, $role: [Int]) {
    assignUserRole(input: { userId: $id, role: $role }) {
      success
      errors
      messages
    }
  }
`;

export const ASSIGN_USER_PERMISSION = gql`
  mutation ASSIGN_USER_PERMISSION($id: String, $permissions: [Int]) {
    assignUserPermission(input: { userId: $id, permissions: $permissions }) {
      success
      errors
      message
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation CREATE_SUPPLIER(
    $fullName: String
    $email: String
    $mobileNumber: String
    $fullAddress: String
  ) {
    createSupplier(
      input: {
        fullName: $fullName
        email: $email
        mobileNumber: $mobileNumber
        fullAddress: $fullAddress
      }
    ) {
      success
      errors
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CREATE_ADDRESS(
    $contentType: String
    $objectId: String
    $addressName: String
    $country: String
    $province: String
    $district: String
    $city: String
    $zipCode: String
    $lineAddressOne: String
    $lineAddressTwo: String
  ) {
    createAddress(
      input: {
        contentType: $contentType
        objectId: $objectId
        addressName: $addressName
        country: $country
        province: $province
        district: $district
        city: $city
        zipCode: $zipCode
        lineAddressOne: $lineAddressOne
        lineAddressTwo: $lineAddressTwo
      }
    ) {
      success
      errors
    }
  }
`;

export const MAKE_SUPPLIER_PAYMENT = gql`
  mutation MAKE_SUPPLIER_PAYMENT(
    $user: String
    $amount: String
    $account: String
    $note: String
  ) {
    makePayment(
      input: { user: $user, amount: $amount, account: $account, note: $note }
    ) {
      success
      errors
      message
    }
  }
`;

export const ADD_EXPENSES = gql`
  mutation ADD_EXPENSES(
    $amount: String
    $note: String
    $account: String
    $transactionSource: String
  ) {
    addExpense(
      input: {
        amount: $amount
        note: $note
        account: $account
        transactionSource: $transactionSource
      }
    ) {
      success
      errors
      message
    }
  }
`;

export const ACCEPT_PAYMENT = gql`
  mutation ACCEPT_PAYMENT(
    $account: String
    $transactionSource: String
    $amount: String
    $note: String
    $user: String
    $discount: String
  ) {
    acceptPayment(
      input: {
        account: $account
        transactionSource: $transactionSource
        amount: $amount
        note: $note
        user: $user
        discount: $discount
      }
    ) {
      success
      errors
      message
    }
  }
`;

export const IMPORT_PRODUCT = gql`
  mutation IMPORT_PRODUCT($excelFile: Upload!) {
    importProduct(input: { excelFile: $excelFile }) {
      success
      message
      errors
      validProducts {
        excelLineNumber
        productName
        unit
        category
        productType
        costPrice
        sellingPrice
        stockQuantity
        barcode
        plu
        tax
      }
      invalidProducts {
        excelLineNumber
        productName
        unit
        category
        productType
        costPrice
        sellingPrice
        stockQuantity
        barcode
        plu
        tax
      }
    }
  }
`;

export const CREATE_TRANSACTION_ACCOUNTS = gql`
  mutation ($name: String!, $transactionCost: Decimal) {
    createTransactionAccount(
      input: { name: $name, transactionCost: $transactionCost }
    ) {
      success
      errors
    }
  }
`;
export const UPDATE_TRANSACTION_ACCOUNTS = gql`
  mutation ($id: String!, $name: String!, $transactionCost: Decimal) {
    updateTransactionAccount(
      input: { id: $id, name: $name, transactionCost: $transactionCost }
    ) {
      errors
      success
      transactionAccount {
        id
        pk
        name
      }
    }
  }
`;
export const DELETE_TRANSACTION_ACCOUNTS = gql`
  mutation ($objectId: String!) {
    deleteTransactionAccount(input: { objectId: $objectId }) {
      errors
      success
      message
    }
  }
`;
export const CHANGE_EFTPOS_TYPE = gql`
  mutation ($eftType: String) {
    changeEftposType(input: { eftType: $eftType }) {
      errors
      success
      message
    }
  }
`;

export const TOGGLE_DEFAULT_TRANSACTION_ACCOUNTS = gql`
  mutation ($objectId: String) {
    toggleDefaultTransactionAccount(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;
export const HANDLE_TOGGLE_TENDER = gql`
  mutation ($objectId: String) {
    toggleRequiredTender(input: { objectId: $objectId }) {
      errors
      success
      message
    }
  }
`;

export const CREATE_PRODUCT_PRINT_ORDERS = gql`
  mutation ($name: String, $productOrder: String) {
    createProductPrintOrder(
      input: { name: $name, productOrder: $productOrder }
    ) {
      errors
      success
    }
  }
`;

export const UPDATE_PRODUCT_PRINT_ORDERS = gql`
  mutation ($name: String, $productOrder: String, $attributeId: String!) {
    updateProductPrintOrder(
      input: {
        name: $name
        productOrder: $productOrder
        attributeId: $attributeId
      }
    ) {
      errors
      success
    }
  }
`;

export const DELETE_PRODUCT_PRINT_ORDERS = gql`
  mutation DELETE_PRODUCT_PRINT_ORDERS($objectId: String!) {
    deleteProductPrintOrder(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;
export const REORDER_PRODUCT_PRINT_ORDERS = gql`
  mutation ($productPrintOrderCategory: [ReOrderProductPrint]) {
    reOrderProductPrintOrder(
      input: { productPrintOrderCategory: $productPrintOrderCategory }
    ) {
      errors
      success
    }
  }
`;

export const UPDATE_PROMOTION = gql`
  mutation (
    $id: String!
    $title: String
    $tax: String
    $compound: String
    $sellingPrice: String
  ) {
    updateProduct(
      input: {
        id: $id
        compound: $compound
        title: $title
        tax: $tax
        sellingPrice: $sellingPrice
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        tax {
          id
          pk
          title
          __typename
        }
        compound {
          id
          pk
          title
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const BULK_PURCHASE_STOCK = gql`
  mutation BULK_PURCHASE_STOCK($purchasedItems: [BulkPurchaseHistoryInput]) {
    bulkCreatePurchaseHistory(input: { purchaseItems: $purchasedItems }) {
      success
      errors
      purchaseHistory {
        pk
        supplier {
          pk
        }
      }
    }
  }
`;

export const MANAGE_STOCK = gql`
  mutation MANAGE_STOCK($products: [ProductStockInput]) {
    bulkStockOut(input: { products: $products }) {
      success
      errors
    }
  }
`;

export const PURCHASE_STOCK = gql`
  mutation PURCHASE_STOCK(
    $supplier: String
    $product: String
    $costPrice: String
    $quantity: String
    $totalCostPrice: String
    $discount: String
    $amountAfterDiscount: String
    $taxAmount: String
    $cpAfterTax: String
  ) {
    createPurchaseHistory(
      input: {
        supplier: $supplier
        product: $product
        costPrice: $costPrice
        quantity: $quantity
        totalCostPrice: $totalCostPrice
        discount: $discount
        amountAfterDiscount: $amountAfterDiscount
        taxAmount: $taxAmount
        cpAfterTax: $cpAfterTax
      }
    ) {
      success
      errors
    }
  }
`;

export const CREATE_BOOKING_AVAILABILITY = gql`
  mutation CREATE_BOOKING_AVAILABILITY(
    $termsAndConditions: String
    $isDefault: Boolean
    $daysDetails: [DaysInput]
  ) {
    createBookingAvailability(
      input: {
        termsAndConditions: $termsAndConditions
        daysDetails: $daysDetails
        isDefault: $isDefault
      }
    ) {
      success
      errors
      message
      bookingAvailability {
        day
        openAt
        endAt
        intervalTime
        isDayOff
      }
    }
  }
`;

export const CREATE_UPDATE_HOLIDAY_PERIOD = gql`
  mutation CREATE_UPDATE_HOLIDAY_PERIOD(
    $holidayStartDate: Date
    $holidayEndDate: Date
  ) {
    createUpdateHolidayPeriod(
      input: {
        holidayStartDate: $holidayStartDate
        holidayEndDate: $holidayEndDate
      }
    ) {
      success
      errors
      message
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation CREATE_BOOKING(
    $table: [String]!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $noOfPeople: String
    $bookingDate: String
    $bookingTime: String
    $note: String
    $acceptTermsAndConditions: String
    $receiveEmails: String
    $customer: String
  ) {
    booking(
      input: {
        table: $table
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        email: $email
        customer: $customer
        noOfPeople: $noOfPeople
        bookingDate: $bookingDate
        bookingTime: $bookingTime
        note: $note
        acceptTermsAndConditions: $acceptTermsAndConditions
        receiveEmails: $receiveEmails
      }
    ) {
      success
      errors
      message
      bookingData {
        noOfPeople
        bookingDate
        bookingTime
        customer {
          user {
            fullName
          }
        }
        note
        table {
          edges {
            node {
              title
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_BOOKING_AVAILABILITY = gql`
  mutation UPDATE_BOOKING_AVAILABILITY(
    $termsAndConditions: String
    $isDefault: Boolean
    $daysDetails: [UpdateDaysInput]
  ) {
    updateBookingAvailability(
      input: {
        termsAndConditions: $termsAndConditions
        daysDetails: $daysDetails
        isDefault: $isDefault
      }
    ) {
      success
      errors
      message
      bookingAvailability {
        day
        openAt
        endAt
        intervalTime
        isDayOff
      }
    }
  }
`;

export const DELETE_CATEGORY_BOOKING__AVAILABILITY = gql`
  mutation DELETE_CATEGORY_BOOKING__AVAILABILITY($objectId: String!) {
    deleteBookingCategory(input: { objectId: $objectId }) {
      success
      errors
      message
    }
  }
`;
