import { gql } from "@apollo/client";
export const PROFILE = gql`
  query PROFILE {
    me {
      id
      fullName
      email
      mobileNumber
      username
      isSuperuser
      isStaff
      isActive
      dateJoined
      assignPermissions {
        id
        pk
        name
        codename
      }
    }
  }
`;

export const CUSTOM_PERMISSIONS = gql`
  query {
    customPermissions
  }
`;

export const CLOCK_IN_CLOCK_OUT_HISTORIES = gql`
  query QUERY_CLOCKIN_CLOCKOUT_HISTORIES(
    $first: Int
    $offset: Int
    $after: String
    $before: String
    $user: ID
  ) {
    clockInClockOutHistories(
      first: $first
      offset: $offset
      after: $after
      before: $before
      user: $user
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          clockIn
          clockOut
          closingBalance
          openingBalance
          totalOrder
          totalSales
          totalCredit
          totalTransaction
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query GET_PRODUCT($id: ID!) {
    product(id: $id) {
      id
      title
      pk
      contentTypeId
      getSku
      getStockQuantity
      getBarcode
      getPlu
      description {
        shortDesc
      }
      productType {
        pk
      }
      unit {
        pk
      }
      compounds {
        pk
        totalPrice
        quantity
        product {
          title
          unit {
            title
          }
        }
      }
      otherCharges {
        id
        pk
        title
        price
      }
      category {
        edges {
          node {
            pk
          }
        }
      }
      media {
        pk
        media
      }
    }
  }
`;
export const QUERY_UNIT = gql`
  query {
    units(first: 100) {
      edges {
        node {
          id
          title
          pk
        }
      }
    }
  }
`;

export const QUERY_CATEGORY = gql`
  query QUERY_CATEGORY(
    $first: Int
    $level: Int
    $offset: Int
    $name_Icontains: String
  ) {
    categories(
      first: $first
      level: $level
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          pk
          name
          totalItems
          image
          status
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query GET_CATEGORY($id: ID!, $first: Int, $offset: Int) {
    category(id: $id) {
      id
      name
      pk
      status
      image
      parent {
        id
        pk
        name
      }
      children(first: $first, offset: $offset) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            name
            id
            pk
            parent {
              name
            }
            children(first: $first, offset: $offset) {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TAX = gql`
  query QUERY_TAX(
    $first: Int
    $after: String
    $before: String
    $title_Icontains: String
  ) {
    taxes(
      first: $first
      after: $after
      before: $before
      title_Icontains: $title_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          title
          rate
          isIncluded
        }
      }
    }
  }
`;

export const GET_TAX = gql`
  query GET_TAX($id: ID!) {
    tax(id: $id) {
      id
      title
      rate
      pk
      isIncluded
    }
  }
`;

export const QUERY_PRINTER = gql`
  query QUERY_PRINTER(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $name_Icontains: String
  ) {
    printers(
      first: $first
      after: $after
      before: $before
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          name
          department
          type
          location
        }
      }
    }
  }
`;

export const GET_PRINTER = gql`
  query GET_PRINTER($id: ID!) {
    printer(id: $id) {
      id
      name
      department
      type
      location
      pk
    }
  }
`;

export const QUERY_SERVICE_CHARGES = gql`
  query QUERY_SERVICE_CHARGE(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
  ) {
    serviceCharges(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          title
          rate
        }
      }
    }
  }
`;

export const GET_SERVICE_CHARGE = gql`
  query GET_SERVICE_CHARGE($id: ID!) {
    serviceCharge(id: $id) {
      id
      title
      rate
      pk
    }
  }
`;
export const POS_SCREEN_ORDERED_ITEMS_LIST = gql`
  query (
    $first: Int
    $offset: Int
    $search: String
    $orderSource: String
    $customerId: ID
  ) {
    orders(
      first: $first
      offset: $offset
      search: $search
      orderSource: $orderSource
      customer: $customerId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          created
          createdBy {
            id
            pk
            fullName
          }
          table {
            title
          }
          customer {
            id
            pk
            user {
              id
              pk
              email
              mobileNumber
              fullName
            }
          }
          kitchenOrder {
            id
            pk
            getInvoiceNumber
          }
          paymentStatus
          totalPrice
          grossAmount
          orderSource
          orderStatus
          orderMethod {
            title
          }
        }
      }
    }
  }
`;

export const QUERY_POS_DISCOUNT = gql`
  query QUERY_POS_DISCOUNT(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $discountType: String
    $title: String
  ) {
    discounts(
      first: $first
      after: $after
      before: $before
      offset: $offset
      discountType: $discountType
      title_Icontains: $title
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          discountType
          title
          rateAmount
        }
      }
    }
  }
`;

export const GET_POS_DISCOUNT = gql`
  query GET_POS_DISCOUNT($id: ID!) {
    discount(id: $id) {
      title
      discountType
      rateAmount
      pk
      id
    }
  }
`;

export const QUERY_RESTAURANT_TABLE = gql`
  query QUERY_RESTAURANT_TABLE(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
    $subTable_Isnull: Boolean
    $isVisible: Boolean
    $isFunctional: Boolean
    $displayInBooking: Boolean
  ) {
    restaurantTables(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
      subTable_Isnull: $subTable_Isnull
      isVisible: $isVisible
      isFunctional: $isFunctional
      displayInBooking: $displayInBooking
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          capacity
          isFunctional
          displayInBooking
          floor {
            title
            pk
          }
          status
          note
        }
      }
    }
  }
`;

export const GET_RESTAURANT_TABLE = gql`
  query GET_RESTAURANT_TABLE($id: ID!) {
    restaurantTable(id: $id) {
      title
      capacity
      floor {
        pk
        title
      }
      note
      pk
      id
    }
  }
`;
export const QUERY_RESTAURANT_FLOOR = gql`
  query QUERY_RESTAURANT_FLOOR(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
  ) {
    floors(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          note
        }
      }
    }
  }
`;
export const GET_RESTAURANT_FLOOR = gql`
  query GET_RESTAURANT_FLOOR($id: ID!) {
    floor(id: $id) {
      title
      note
      pk
      id
      layout

      tablesByFloor(isVisible: true, subTable_Isnull: true) {
        edges {
          node {
            pk
            id
            title
            status
            capacity
            note
            isMerged
            isMergedTable
            isVisible
            isSubTableVisible
            subTables(isVisible: true) {
              edges {
                node {
                  pk
                  id
                  title
                  status
                  capacity
                  note
                  isMerged
                  isMergedTable
                  isVisible
                  isSubTableVisible
                  currentKitchenOrder {
                    id
                    pk
                  }
                }
              }
            }
            currentKitchenOrder {
              id
              pk
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TERMINAL = gql`
  query QUERY_TERMINAL(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $name_Icontains: String
  ) {
    terminals(
      first: $first
      after: $after
      before: $before
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      edges {
        node {
          id
          pk
          name
          note
          printer {
            name
          }
        }
      }
    }
  }
`;
export const GET_TERMINAL = gql`
  query GET_TERMINAL($id: ID!) {
    terminal(id: $id) {
      id
      name
      printer {
        id
        name
        pk
      }
      note
      terminalsetting {
        id
        isActive
        isScreenSaveOnly
        slideDuration
        isOpenPopupAfterPayment
        netAmount
        amountAfterDiscount
        gst
        surCharge
        serviceCharge
        eftposCharge
        grossAmount
        openPopupAfterRemotePrint
        serviceChargeToggleCheckbox
        compulsoryKitchenPrint
        scalePort
        scaleBaudRate
        scaleDateBits
        scaleParity
        scaleStopBits
        isActiveScale
        pk
        screensaverimageSet {
          edges {
            node {
              pk
              image
            }
          }
        }
      }
      pk
    }
  }
`;
export const QUERY_USERS = gql`
  query QUERY_USERS(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $email_Icontains: String
    $isStaff: Boolean
  ) {
    users(
      first: $first
      after: $after
      before: $before
      offset: $offset
      email_Icontains: $email_Icontains
      isStaff: $isStaff
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          fullName
          username
          pk
          email
          mobileNumber
          isStaff
          isSuperuser
          isActive
          verified
          isCustomer
          groups {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($id: ID!) {
    user(id: $id) {
      username
      email
      mobileNumber
      isStaff
      isSuperuser
      pk
      assignPermissions {
        id
        name
        codename
        pk
      }
      groups {
        edges {
          node {
            id
            pk
          }
        }
      }
    }
  }
`;
export const TOGGLE_USER_STATUS = gql`
  mutation ($userId: String, $status: String) {
    toggleUserStatus(input: { userId: $userId, status: $status }) {
      errors
      success
    }
  }
`;

export const QUERY_ROLES = gql`
  query QUERY_ROLES(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $name_Icontains: String
  ) {
    roles(
      first: $first
      after: $after
      before: $before
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          name
          userSet {
            edges {
              node {
                username
              }
            }
          }
          permissions {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ROLE = gql`
  query GET_ROLE($id: ID!) {
    role(id: $id) {
      name
      pk
      id
      userSet {
        edges {
          node {
            username
            email
            mobileNumber
          }
        }
      }
      permissions {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const QUERY_PERMISSIONS = gql`
  query QUERY_PERMISSIONS($first: Int, $after: String, $before: String) {
    permissions(first: $first, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          codename
          pk
          id
        }
      }
    }
  }
`;

export const QUERY_DASHBOARD = gql`
  query QUERY_DASHBOARD {
    dashboardReportObjectCount {
      customers
      orders
      categories
      users
      kitchenOrders
      products
      modifierMaterial
      income
      expense
      todaySale
      todayOrders
      profitLoss
      customerCredit
      todayExpense
      todayIncome
      todayProfitLoss
      todayCredit
    }
  }
`;

export const QUERY_SUPPLIER = gql`
  query QUERY_SUPPLIER(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
  ) {
    suppliers(
      first: $first
      after: $after
      before: $before
      offset: $offset
      search: $search
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          pk
          outstandingBalance
          supplierPurchaseHistory {
            edges {
              node {
                id
                pk
                created
                totalCostPrice
                cpAfterTax
                discount
                amountAfterDiscount
                taxAmount
                cpAfterTax
                product {
                  title
                }
              }
            }
          }
          user {
            id
            pk
            mobileNumber
            email
            fullName
            profile {
              fullAddress
            }
          }
        }
      }
    }
  }
`;

export const GET_SUPPLIER = gql`
  query GET_SUPPLIER($id: ID!) {
    supplier(id: $id) {
      id
      pk
      created
      user {
        pk
        mobileNumber
        email
        profile {
          fullAddress
        }
      }
    }
  }
`;

export const QUERY_TRANSACTIONS_VIEW = gql`
  query transactions(
    $first: Int
    $offset: Int
    $before: String
    $after: String
    $account: ID
    $createdGte: DateTime
    $createdLte: DateTime
    $account_IsVisible: Boolean
  ) {
    transactions(
      first: $first
      after: $after
      before: $before
      offset: $offset
      account: $account
      created_Gte: $createdGte
      created_Lte: $createdLte
      account_IsVisible: $account_IsVisible
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalBalance
      edges {
        node {
          id
          pk
          created
          debit
          credit
          note

          transactionSource
          createdBy {
            username
            fullName
          }
          terminal
          account {
            id
            pk
            name
            accountBalance
          }
          order {
            id
            pk
            getInvoiceNumber
            paymentStatus
            receiptId
            totalPrice
            paymentMethod
            orderMethod {
              title
            }
            tax
            serviceCharge
            discount
            grossAmount
            kitchenOrder {
              getInvoiceNumber
              receiptId
            }
            table {
              id
              pk
              title
              capacity
            }
            customer {
              id
              pk
              user {
                profile {
                  id
                  pk
                  fullName
                }
              }
            }
            orderItems {
              edges {
                node {
                  id
                  pk
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TRANSACTION_ACCOUNTS = gql`
  query ($first: Int, $offset: Int, $isVisible: Boolean) {
    transactionAccounts(first: $first, offset: $offset, isVisible: $isVisible) {
      edges {
        node {
          id
          pk
          name
          isRequiredTender
          isDefault
          transactionCost
        }
      }
    }
  }
`;

export const GET_TRANSACTION_ACCOUNT = gql`
  query ($id: ID!) {
    transactionAccount(id: $id) {
      id
      pk
      name
      transactionCost
    }
  }
`;
export const DASHBOARD_SALES_GRAPH = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardSalesGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      salesCount
      salesAmount
      productsCount
      productsAmount
      graphData {
        label
        salesCount
        salesAmount
        productsCount
        productsAmount
      }
    }
  }
`;
export const DASHBOARD_ORDER_GRAPH = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardOrderGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      totalOrderAmount
      totalOrderCount
      graphData {
        label
        count
        amount
      }
    }
  }
`;
export const DASHBOARD_EXPENSES_GRAPH = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardExpensesGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      refundOrderCount
      refundOrderAmount
      voidOrderCount
      voidOrderAmount
      discountOrderCount
      discountOrderAmount
      rewardProgramOrderCount
      rewardProgramOrderAmount
      graphData {
        label
        discountCount
        discountAmount
        refundCount
        refundAmount
        voidCount
        voidAmount
        rewardProgramCount
        rewardProgramAmount
      }
    }
  }
`;
export const QUERY_OBJECT_COUNT = gql`
  query {
    dashboardReportObjectCount {
      todaySaleCount
      todaySaleIncrement
      todayProductSaleCount
      todayProductSaleIncrement
      todayModifierSaleCount
      todayModifierSaleIncrement
      todayCustomerCount
      todayCustomerIncrement
      overAllSaleCount
      overAllSaleAmount
      overAllProductCount
      overAllProductAmount
      overAllModifierCount
      overAllModifierAmount
      overAllSaleAverageCount
      overAllSaleAverageAmount
    }
  }
`;
export const GET_TRANSACTION_ACCOUNTS = gql`
  query (
    $account: ID
    $first: Int
    $offset: Int
    $created_Gte: DateTime
    $created_Lte: DateTime
  ) {
    transactions(
      account: $account
      first: $first
      offset: $offset
      created_Gte: $created_Gte
      created_Lte: $created_Lte
    ) {
      edges {
        node {
          id
          pk

          created
          debit
          credit
          note
          account {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const GET_QUERY_SUPPLIER_TRANSACTIONS_VIEW = gql`
  query (
    $first: Int
    $offset: Int
    $transaction_User: ID!
    $transaction_Created_Lte: DateTime
    $transaction_Created_Gte: DateTime
  ) {
    supplierTransactionsView(
      first: $first
      offset: $offset
      transaction_User: $transaction_User
      transaction_Created_Gte: $transaction_Created_Gte
      transaction_Created_Lte: $transaction_Created_Lte
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          balance
          transaction {
            created
            id
            pk
            debit
            credit
            note
            user {
              id
              pk
              mobileNumber
              email
              profile {
                id
                pk
                fullName
                email
                contactNumber
                dobEnglish
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;

export const PURCHASE_HISTORIES = gql`
  query PURCHASE_HISTORIES(
    $first: Int
    $offset: Int
    $transaction_User: ID
    $created_Gte: DateTime
    $created_Lte: DateTime
  ) {
    purchaseHistories(
      first: $first
      offset: $offset
      supplier: $transaction_User
      created_Gte: $created_Gte
      created_Lte: $created_Lte
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          created
          id
          pk
          costPrice
          quantity
          totalCostPrice
          discount
          amountAfterDiscount
          taxAmount
          cpAfterTax
          product {
            id
            title
          }
          supplier {
            id
          }
        }
      }
    }
  }
`;
export const QUERY_CUSTOMER_TRANSACTIONS_VIEW = gql`
  query ($first: Int, $offset: Int) {
    customerTransactionsView(first: $first, offset: $offset) {
      edges {
        node {
          id
          pk
          transaction {
            id
            pk
            user {
              pk
              mobileNumber
              email
              profile {
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_QUERY_CUSTOMER_TRANSACTIONS_VIEW = gql`
  query (
    $first: Int
    $offset: Int
    $transaction_User: ID!
    $transaction_Created_Gte: DateTime
    $transaction_Created_Lte: DateTime
  ) {
    customerTransactionsView(
      first: $first
      offset: $offset
      transaction_User: $transaction_User
      transaction_Created_Gte: $transaction_Created_Gte
      transaction_Created_Lte: $transaction_Created_Lte
    ) {
      edges {
        node {
          id
          pk
          transaction {
            created
            id
            pk
            debit
            credit
            note
            order {
              id
              pk
              getInvoiceNumber
              paymentStatus
              orderItems {
                edges {
                  node {
                    id
                    pk
                    title
                    quantity
                    price
                    totalPrice
                  }
                }
              }
            }
            user {
              id
              pk
              mobileNumber
              email
              profile {
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_WEBSITE_DISCOUNT_SALES = gql`
  query QUERY_WEBSITE_DISCOUNT(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $discountType: String
  ) {
    websiteDiscounts(
      first: $first
      after: $after
      before: $before
      offset: $offset
      discountType: $discountType
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          discountType
          title
          rateAmount
          start
          end
        }
      }
    }
  }
`;
export const GET_SALES_DISCOUNT = gql`
  query GET_SALE_DISCOUNT($id: ID!) {
    websiteDiscount(id: $id) {
      title
      discountType
      rateAmount
      pk
      id
    }
  }
`;
export const HOURLY_SALES_REPORT = gql`
  query HOURLY_SALES_REPORT($fromDate: Date, $toDate: Date) {
    hourlyTransactionHistory(fromDate: $fromDate, toDate: $toDate) {
      date {
        name
        count
        amount
      }
      fromDate
      toDate
    }
  }
`;
export const DETAIL_SALES_REPORT = gql`
  query DETAIL_SALES_REPORT(
    $user: [String]
    $category: [String]
    $items: [SaleItemInputTypeNode]
    $table: [String]
    $paymentMethods: [String]
    $isTaxable: Boolean
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    detailSaleReport(
      user: $user
      category: $category
      item: $items
      table: $table
      paymentMethods: $paymentMethods
      isTaxable: $isTaxable
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      createdBy
      createdOn
      grossSale
      refunds {
        count
        amount
      }
      discounts {
        count
        amount
      }
      cashOut {
        count
        amount
      }
      rewardsReedem {
        count
        amount
      }
      accountTransaction {
        name
        amount
        count
      }
      staffPerformance {
        name
        amount
        count
      }
      orderStatistics {
        name
        amount
        count
      }
      totalTransaction
      netSales
      gst
      surCharge
      netBalance
      serCharge
      eftposCharge
      gstSale
      gstFreeSale
      cashBalance
      totalCashOut
      totalOrder
      floatAmount
    }
  }
`;
export const GET_FLOAT_AMOUNT = gql`
  query {
    todayFloatAmount {
      pk
      amount
      notesReconciliation {
        hundred
        fifty
        twenty
        ten
        five
        two
        one
      }
      coinsReconciliation {
        fifty
        twenty
        ten
        five
      }
    }
  }
`;

export const CREATE_FLOAT_AMOUNT = gql`
  mutation CREATE_FLOAT_AMOUNT(
    $floatAmount: Decimal!
    $coinsReconciliation: CoinsReconciliationInputType
    $notesReconciliation: NotesReconciliationInputType
    $terminal: [TerminalInputType]
  ) {
    createFloatAmount(
      input: {
        floatAmount: $floatAmount
        coinsReconciliation: $coinsReconciliation
        notesReconciliation: $notesReconciliation
        terminal: $terminal
      }
    ) {
      success
      message
      floatAmount {
        pk
        amount
        terminals {
          name
        }
        notesReconciliation {
          hundred
          fifty
          twenty
          ten
          five
          two
          one
        }
        coinsReconciliation {
          fifty
          twenty
          ten
          five
        }
      }
    }
  }
`;

export const QUICK_SALE_REPORT = gql`
  query QUICK_SALES_REPORT(
    $paymentMethods: [String]
    $taxType: String!
    $fromDateTime: DateTime!
    $toDateTime: DateTime!
  ) {
    quickSaleReport(
      paymentMethods: $paymentMethods
      taxType: $taxType
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      modeOfPayment {
        name
        price
        quantity
      }
      gstItem
      gstFreeItem
      totalSales
      totalAmountInTill
      float
      createdBy
      createdOn
    }
  }
`;

export const EMPLOYEE_WORKING_HOUR_REPORT = gql`
  query EMPLOYEE_WORKING_HOUR_REPORT(
    $user: [String]
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    employeeWorkingHourReport(
      user: $user
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      createdBy
      createdOn
      data {
        name
        clockInOut {
          clockIn
          clockOut
          difference
        }
      }
    }
  }
`;

export const STOCK_REPORT = gql`
  query STOCK_REPORT(
    $category: [String]
    $items: [SaleItemInputTypeNode]
    $isTaxable: Boolean
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    productStockHistoryReport(
      category: $category
      item: $items
      isTaxable: $isTaxable
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      category {
        name
        quantity
      }
      items {
        name
        quantity
      }
      gstItem
      gstFreeItem
      totalStock
      createdBy
      createdOn
    }
  }
`;

export const MEMBERS_REPORT = gql`
  query MEMBERS_REPORT($fromDateTime: DateTime, $toDateTime: DateTime) {
    membersReport(fromDateTime: $fromDateTime, toDateTime: $toDateTime) {
      fromDate
      toDate
      name
      members {
        name
        joinedOn
      }
      joinedOn
      createdBy
      createdOn
    }
  }
`;

export const NO_SALES_REPORT = gql`
  query NO_SALES_REPORT(
    $user: [String]
    $items: [SaleItemInputTypeNode]
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    noSaleReport(
      user: $user
      item: $items
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      createdOn
      createdBy
      fromDate
      toDate
      noSale
    }
  }
`;

export const WASTAGE_AND_LOSS_REPORT = gql`
  query WASTAGE_AND_LOSS_REPORT(
    $user: [String]
    $category: [String]
    $items: [SaleItemInputTypeNode]
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    wastageAndLossReport(
      user: $user
      category: $category
      item: $items
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      createdOn
      createdBy
      fromDate
      toDate
      user {
        username
        fullName
      }
      category {
        name
        price
        quantity
      }
      items {
        name
        price
        quantity
      }
      totalAmount
    }
  }
`;

export const PRODUCTWISE_REPORT = gql`
  query PRODUCTWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $query: String
    $terminal: [String]
  ) {
    detailProductWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
      query: $query
    ) {
      product
      quantity
      totalCost
      totalSales
      profitLoss
      subRows {
        product
        quantity
        totalCost
        totalSales
        profitLoss
      }
    }
  }
`;
export const CANCELLED_PRODUCTWISE_REPORT = gql`
  query CANCELLED_PRODUCTWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $product: [String]
  ) {
    detailProductCancelReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      product: $product
    ) {
      name
      quantity
      table
      operator
      dateTime
      totalPrice
    }
  }
`;

export const CATEGORYWISE_REPORT = gql`
  query CATEGORYWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailCategoriesWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      category
      quantity
      totalCost
      totalSales
      profitLoss
    }
  }
`;

export const USERWISE_REPORT = gql`
  query USERWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailUserWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      totalCost
      totalTransaction
    }
  }
`;

export const FLOORWISE_REPORT = gql`
  query FLOORWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailFloorWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      tableCount
      totalSales
      totalTransaction
      profitLoss
    }
  }
`;
export const TABLEWISE_REPORT = gql`
  query TABLEWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailTableWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      totalSales
      totalTransaction
      profitLoss
    }
  }
`;

export const PRODUCT_PRINT_ORDERS = gql`
  query PRODUCT_PRINT_ORDERS {
    productPrintOrders {
      edges {
        node {
          id
          name
          productOrder
          pk
        }
      }
    }
  }
`;

export const PRODUCT_PRINT_ORDER = gql`
  query ($id: ID!) {
    productPrintOrder(id: $id) {
      id
      name
      pk
      productOrder
    }
  }
`;

export const REWARD_SETTING_POINT = gql`
  query {
    rewardPointSetting {
      amount
      point
      minRequiredPoint
      isActive
    }
  }
`;

export const USER_LOGIN = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(input: { username: $username, password: $password }) {
      token
      success
      errors
      user {
        id
        email
        username
        dateJoined
        userPermissions {
          edges {
            node {
              name
              codename
            }
          }
        }
      }
    }
  }
`;
export const PRODUCTS_BY_MODIFIERCATEGORY = gql`
  query {
    modifierPlaceHolders {
      edges {
        node {
          id
          pk
          color
        }
      }
    }
  }
`;
export const ACCOUNT_TRANSACTION = gql`
  query ACCOUNT($slug: String) {
    transactionAccounts(slug: $slug) {
      edges {
        node {
          accountBalance
          name
          id
          pk
          children {
            edges {
              node {
                name
                accountBalance
                id
                pk
              }
            }
          }
        }
      }
    }
  }
`;
export const POS_SCREEN_ORDERED_ITEMS = gql`
  query ($id: ID!, $first: Int, $offset: Int, $before: String, $after: String) {
    order(id: $id) {
      id
      pk
      getInvoiceNumber
      accountFee
      authorizedBy
      rewardPoint
      created
      note
      terminal
      reason
      orderMethod {
        title
      }
      kitchenOrder {
        id
        pk
        getInvoiceNumber
      }
      createdBy {
        id
        pk
        username
        fullName
      }

      note
      totalPrice
      discount
      surCharge
      tax
      serviceCharge
      grossAmount
      paymentStatus
      paymentMethod
      orderSource
      orderStatus
      changeAmount
      paidAmount
      orderTransactions {
        edgeCount
        totalCount
      }

      customer {
        id
        pk
        user {
          id
          pk
          username
          isStaff
          mobileNumber
          fullName
          profile {
            id
            pk
            email
          }
        }
      }
      table {
        id
        pk
        title
        status
      }
      orderMethod {
        id
        title
      }
      orderItems(
        first: $first
        after: $after
        before: $before
        offset: $offset
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            pk
            quantity
            discount
            surCharge
            totalPrice
            grossAmount
            price
            title
            product {
              id
              pk
              title
              finalPrice
              sellingPrice
              costPrice
              taxAmount
              tax {
                id
                pk
                rate
                isIncluded
              }
            }
            modifier {
              id
              pk
              title
              quantity
              totalPrice
              discount
              product {
                id
                pk
                finalPrice
                sellingPrice
                title
                costPrice
                taxAmount
                tax {
                  id
                  pk
                  rate
                  isIncluded
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_STOCK_HISTORIES = gql`
  query ($product: ID!, $offset: Int, $first: Int) {
    purchaseHistories(product: $product, offset: $offset, first: $first) {
      edges {
        node {
          created
          id
          pk
          costPrice
          quantity
          totalCostPrice
          discount
          amountAfterDiscount
          taxAmount
          cpAfterTax
          product {
            id
            pk
            title
          }
          supplier {
            id
            pk
            user {
              id
              pk
              email
              username
              profile {
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_CATEGORY_TIME_INTERVALS = gql`
  query QUERY_CATEGORY_TIME_INTERVALS($selectedDate: Date, $table: [String]) {
    categoryTimeIntervals(selectedDate: $selectedDate, table: $table) {
      categoryName
      day
      timeIntervals {
        interval
        isBooked
      }
      categoryId
    }
  }
`;
export const QUERY_TERMS_AND_CONDITION = gql`
  query QUERY_TERMS_AND_CONDITION {
    termsAndConditions {
      edges {
        node {
          termsAndConditions
        }
      }
    }
  }
`;
export const QUERY_HOLIDAY_PERIOD = gql`
  query QUERY_HOLIDAY_PERIOD {
    holidayPeriods {
      edges {
        node {
          startDate
          endDate
        }
      }
    }
  }
`;
export const QUERY_ALL_BOOKING_CATEGORIES = gql`
  query QUERY_ALL_BOOKING_CATEGORIES($pk: UUID) {
    bookingCategories(id: $pk) {
      edges {
        node {
          name
          pk
          id
          isDefault
          availabilities {
            edges {
              node {
                day
                openAt
                endAt
                isDayOff
                intervalTime
                pk
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_BOOKING_STATUS = gql`
  query QUERY_BOOKING_STATUS($selectedDate: Date) {
    bookingStatus(selectedDate: $selectedDate) {
      message
      tableData {
        tableName
        timeIntervals {
          timeFrame
          bookings {
            customerName
            noOfPeople
            bookingId
          }
        }
      }
      startEndTime {
        startTime
        endTime
      }
    }
  }
`;
export const QUERY_BOOKING_DETAILS = gql`
  query QUERY_BOOKING_DETAILS($id: UUID!) {
    bookings(id: $id) {
      edges {
        node {
          noOfPeople
          note
          customer {
            user {
              fullName
              mobileNumber
              email
            }
          }
        }
      }
    }
  }
`;
export const QUERY_BOOKING_DATE = gql`
  query QUERY_BOOKING_DATE {
    bookedDate {
      bookedDates
    }
  }
`;
